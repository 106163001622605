.my-payments {

  .card-title {
  	color: #4A4A4A;
    font-family: "LatoWeb";
  	font-size: 16px;
  	letter-spacing: 0.84px;
  	line-height: 19px;
    margin-bottom: 30px;
  }

  .my-payments-margin {
    margin-bottom: 23px;
  }

  .my-payments-table-container {
    margin-bottom: 40px;
    margin-top: 20px;

    th {
    	color: #115EA0;
      font-family: "LatoWeb";
    	font-size: 14px;
    	font-weight: 600;
    	letter-spacing: 0.73px;
    	line-height: 13px;
      padding-top: 23px;
      padding-bottom: 23px;
      padding-left: 20px;
    }

    td {
      vertical-align: middle;
      padding-left: 20px;
    	color: #6D6D6D;
      font-family: "LatoWeb";
    	font-size: 14px;
    	letter-spacing: 0.73px;
    	line-height: 13px;
    }

    .bill {
      color: #115EA0;
    }

    .check {
      color: #00B047;
	    font-style: italic;
    }

    .selectable {
      cursor: pointer;
      &:hover {
        opacity: 0.7;
        text-decoration: underline;
      }
    }

    label {
      margin: 0;
    }

    .partial {
      text-decoration: underline;
      cursor: pointer;
      &:hover {
        opacity: 0.7;

      }
    }

    .link-arrow {
      margin-left: 5px;
    }
  }

  .next-payment_container {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }

  .next-payment_container-row {
    display: inline-block;
  }

  .next-payment_container-border {
    border-right: solid 1px #D8D8D8;
  }

  .next-payment_button-container {
    margin-bottom: 30px;
  }

  .next-payment_title {
  	color: #6D6D6D;
    font-family: "LatoWeb";
  	font-size: 14px;
  	letter-spacing: 0.56px;
  	line-height: 17px;
    margin-bottom: 5px;
  }

  .next-payment_value {
  	color: #115EA0;
    font-family: "LatoWeb";
  	font-size: 16px;
  	font-weight: bold;
  	letter-spacing: 0.57px;
  	line-height: 19px;
  }

  .submit-button {
  	border-radius: 3px;
  	background-color: #00B047;
  }

}

.full-modal {
  .modal-lg {
    max-width: 90%;
    height: 90%;

    .modal-content {
      height: 100%;
    }
  }
}
