.reports {
  .space-between {
    justify-content: space-between;
  }

  .report-button {
    width: auto;
  }

  .card-title-transaction {
    color: #4a4a4a;
    font-family: "LatoWeb";
    font-size: 16px;
    letter-spacing: 0.84px;
    line-height: 19px;
  }

  .reports-margin {
    margin-bottom: 30px;
  }

  .reports-margin-top {
    margin-top: 30px;
  }

  .reports-margin-top {
    margin-top: 20px;
  }

  .users-tab-search-title {
    color: #115ea0;
    font-family: "LatoWeb";
    font-size: 14px;
    font-style: italic;
    letter-spacing: 0.73px;
    line-height: 17px;
    display: flex;
    align-items: center;
  }

  .users-tab-search-title-margin {
    margin-bottom: 15px;
  }

  .results-table-container {
    overflow: auto;
    height: 250px;
    margin-bottom: 10px;

    th {
      color: #115ea0;
      font-family: "LatoWeb";
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0.73px;
      line-height: 13px;
      text-align: center;
      vertical-align: middle;
    }

    .notice-th {
      color: #00b047;
      font-family: "LatoWeb";
      font-size: 14px;
      font-style: italic;
      font-weight: bold;
      letter-spacing: 0.73px;
      line-height: 13px;
      text-align: center;
    }

    td {
      color: #6d6d6d;
      font-family: "LatoWeb";
      font-size: 14px;
      letter-spacing: 0.73px;
      line-height: 13px;
    }
  }

  .reports_user {
    border-bottom: solid 1px #d8d8d8;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }

  .reports_user-key-container {
    display: flex;
    margin-bottom: 15px;
  }

  .reports_user-key {
    color: #115ea0;
    font-family: "LatoWeb";
    font-size: 14px;
    letter-spacing: 0.84px;
    line-height: 14px;
  }

  .reports_user-value {
    color: #717171;
    font-family: "LatoWeb";
    font-size: 14px;
    letter-spacing: 0.63px;
    line-height: 17px;
    display: flex;
    align-items: center;
  }

  .reports_user-image {
    height: 63px;
    width: 63px;
    border-radius: 50%;
    object-fit: cover;
  }

  .reports_general-key {
    color: #6d6d6d;
    font-family: "LatoWeb";
    font-size: 14px;
    letter-spacing: 0.73px;
    margin-bottom: 15px;
    line-height: 17px;
    span,
    .bold {
      font-weight: bold;
    }
  }

  .report-point-container {
    padding: 0;
  }

  .report-point {
    margin-top: 2px;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background: #1d69ae;
  }

  .light {
    background: #44C4E2;
  }

  .donut-container {
    justify-content: center;
  }

  .donut-padding {
    padding: 0!important;
  }

  .donut {
    position: relative;
    margin-bottom: 20px;
  }

  .reports_donut-title {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .reports_donut-title_value {
    font-family: "LatoWeb";
    font-size: 16px;
    font-weight: bold;
    color: #6d6d6d;
  }

  .reports_donut-title_description {
    color: #115EA0;
    font-family: "LatoWeb";
    font-size: 14px;
    letter-spacing: 0.73px;
    line-height: 17px;
    text-align: center;
  }

  .reports_general-value {
    color: #6d6d6d;
    font-family: "LatoWeb";
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1.31px;
    line-height: 17px;
    text-align: center;
  }

  // .reports_general-separator {
  //   border-right: solid 1px #D8D8D8;
  // }

  .reports_general-container {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .flex-wrapper {
    display: flex;
    flex-flow: row nowrap;
  }

  .flex-justify {
    flex-direction: column;
    flex-wrap: wrap;
  }

  .single-chart {
    width: 100%;
    justify-content: space-around;
  }

  .circular-chart {
    display: block;
    margin: 10px auto 30px auto;
    max-width: 70%;
    max-height: 250px;
  }

  .circle-bg {
    fill: none;
    stroke: #9c9c9c;
    stroke-width: 2;
  }

  .circle {
    fill: none;
    stroke-width: 4;
    animation: progress 1s ease-out forwards;
  }

  @keyframes progress {
    0% {
      stroke-dasharray: 0 100;
    }
  }

  .circular-chart.orange .circle {
    stroke: #ff9f00;
  }

  .circular-chart.green .circle {
    stroke: #4cc790;
  }

  .circular-chart.blue .circle {
    stroke: #005ea3;
  }

  .percentage {
    fill: #666;
    font-family: sans-serif;
    font-size: 0.5em;
    text-anchor: middle;
  }
}
