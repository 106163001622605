.footer-login {
  height: 64px!important;
}

.footer {
  height: 64px;
  background-color: #EFEFEF;

  .footer-container {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  .footer-logo {
    height: 31px;
  }

  .footer-logo-home {
    height: 25px;
  }

  .footer-text {
    color: #4A4A4A;
    font-family: "LatoWeb";
    font-size: 14px;
    letter-spacing: 0.89px;
    line-height: 17px;
    margin: 0 9px 0 0;
  }

  .footer-left-container {
    display: flex;
    height: 64px;
    align-items: center;
  }

  .footer-right-container {
    display: flex;
    height: 64px;
    align-items: center;
    justify-content: flex-end;
  }

  .footer-text-right {
    margin: 0 4px 0 0;
  }

  .footer-text-bold {
  	color: #4A4A4A;
    font-family: "LatoWebBold";
    font-size: 14px;
    letter-spacing: 1.02px;
    line-height: 17px;
    margin: 0;
  }

}

@media only screen and (max-width: 64em) {
  .footer {
    height: auto;
    .footer-left-container {
      margin-top: 10px;
      height: 35px;
    }

    .footer-right-container {
      height: 35px;
      margin-bottom: 10px;
    }
  }

  .footer-login {
    height: 64px!important;
  }

}
