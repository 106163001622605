.user-header {

  .user-header-profile-container {
    margin-bottom: 15px;
  }

  .user-header-profile-title {
    font-family: "LatoWeb";
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 1.29px;
    line-height: 24px;
    color: #115EA0;
  }

  .user-header-profile-subtitle {
    font-family: "LatoWeb";
    color: #8B8B8B;
    font-size: 12px;
    letter-spacing: 0.39px;
    line-height: 15px;
  }

  .user-header-profile-name {
    font-family: "LatoWeb";
    font-size: 14px;
    letter-spacing: 0.45px;
    line-height: 17px;
    color: #4A4A4A;
    text-transform: capitalize;
  }

  .user-header-company-name {
    font-family: "LatoWeb";
    line-height: 17px;
    letter-spacing: 1.24px;
    font-size: 14px;
    color: #4A4A4A;
    margin-top: 6px;
    text-transform: capitalize;
  }

}
