.claim {
  .input-date {
    margin-top: 0px !important;
    margin-bottom: 5px !important;
  }

  .search-date-container {
    height: 100%;
    align-items: flex-end;
  }

  .card-title-transaction {
    color: #4a4a4a;
    font-family: "LatoWeb";
    font-size: 16px;
    letter-spacing: 0.84px;
    line-height: 19px;
  }

  .card-sub-title {
    color: #115ea0;
    font-family: "LatoWeb";
    font-size: 13px;
    font-style: italic;
    letter-spacing: 0.68px;
    line-height: 19px;
  }

  .claim-margin {
    margin-bottom: 30px;
  }

  .search-notice-icon-container {
    display: flex;
    justify-content: center;
    margin-bottom: 19px;
    margin-top: 40px;
  }

  .search-notice-icon {
    height: 60px;
  }

  .search-notice-text {
    color: #4a4a4a;
    font-family: "LatoWeb";
    font-size: 14px;
    letter-spacing: 0.73px;
    line-height: 19px;
    text-align: center;
    margin-bottom: 35px;
  }

  .search-notice-form {
    .MuiFormControl-root-1 {
      margin-top: 0;
    }
  }

  .errors-title {
    color: #f50b0b;
    font-family: "LatoWeb";
    font-size: 16px;
    font-style: italic;
    letter-spacing: 0.68px;
    line-height: 19px;
    margin-bottom: 16px;
  }

  .success-title {
    color: #115ea0;
    font-family: "LatoWeb";
    font-size: 16px;
    font-style: italic;
    font-weight: bold;
    letter-spacing: 0.94px;
    line-height: 19px;
    margin-bottom: 16px;
    margin-top: 16px;
  }

  .claim-margin-top {
    margin-top: 20px;
  }

  .results-table-container {
    height: 250px;
    overflow: auto;
    margin-bottom: 20px;

    th {
      color: #115ea0;
      font-family: "LatoWeb";
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0.73px;
      line-height: 13px;
      text-align: center;
      vertical-align: middle;
    }

    .notice-th {
      color: #00b047;
      font-family: "LatoWeb";
      font-size: 14px;
      font-style: italic;
      font-weight: bold;
      letter-spacing: 0.73px;
      line-height: 13px;
      text-align: center;
    }

    td {
      color: #6d6d6d;
      font-family: "LatoWeb";
      font-size: 14px;
      letter-spacing: 0.73px;
      line-height: 13px;
    }

    .notice-td-container {
      display: flex;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
        text-decoration: underline;
      }
    }

    .notice-td {
      color: #00b047;
      font-family: "LatoWeb";
      font-size: 14px;
      font-style: italic;
      letter-spacing: 0.73px;
      line-height: 13px;
    }

    .notice-td-link {
      color: #9b9b9b;
      font-family: "LatoWeb";
      font-size: 14px;
      font-style: italic;
      letter-spacing: 0.73px;
      line-height: 13px;
    }

    .enabled-link {
      cursor: pointer;
      color: #00b047;
      &:hover {
        opacity: 0.7;
      }
    }

    .input-error {
      color: red;
      width: 100px;
    }

    .notice-td-check {
      color: #00b047;
      font-family: "LatoWeb";
      font-size: 14px;
      letter-spacing: 0.73px;
      line-height: 13px;
    }

    .link-arrow {
      margin-left: 5px;
    }

    .notice-td-second {
      text-decoration: underline;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
    }

    label {
      margin: 0;
    }

    .MuiButtonBase-root-102 {
      padding: 0;
    }

    .invoice {
      color: #115ea0;
    }

    .check {
      color: #239175;
    }
  }

  .claim-upload-icon {
    height: 72px;
  }

  .claim-upload-left {
    text-align: left;
  }

  .claim-upload-title {
    color: #00b047;
    font-family: "LatoWeb";
    font-size: 16px;
    font-style: italic;
    font-weight: 500;
    letter-spacing: 0.84px;
    line-height: 19px;
  }

  .margin-succes {
    margin-top: 35px;
  }

  .check-icon-container {
    display: flex;
    align-items: center;
  }

  .check-icon {
    height: 14px;
    margin-left: 5px;
  }

  .date-search-row {
    justify-content: space-between;
  }
}
