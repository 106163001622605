.refunds {
  .input-date {
    margin-top: 0px !important;
    margin-bottom: 5px !important;
  }

  .search-date-container {
    height: 100%;
    align-items: flex-end;
  }

  .card-title {
  	color: #4A4A4A;
    font-family: "LatoWeb";
  	font-size: 16px;
  	letter-spacing: 0.84px;
  	line-height: 19px;
    margin-bottom: 30px;
  }

  .refunds-margin {
    margin-bottom: 23px;
  }

  .refunds-table-container {
    margin-bottom: 10px;
    margin-top: 20px;
    display: block;
    width: 100%;
    overflow: auto;
    height: 250px;

    th {
    	color: #115EA0;
      font-family: "LatoWeb";
    	font-size: 14px;
    	font-weight: 600;
    	letter-spacing: 0.73px;
    	line-height: 13px;
      padding-top: 13px;
      padding-bottom: 13px;
      padding-left: 20px;
      vertical-align: middle;
    }

    td {
      vertical-align: middle;
      padding-left: 20px;
    	color: #6D6D6D;
      font-family: "LatoWeb";
    	font-size: 14px;
    	letter-spacing: 0.73px;
    	line-height: 13px;
      white-space: nowrap;
    }

    .document {
      color: #00B047;
      text-decoration: underline;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
    }

    .center {
      text-align: center;
    }

    .subtitle {
    	color: #4A4A4A;
      font-family: "LatoWeb";
    	font-size: 12px;
    	font-style: italic;
    	letter-spacing: 0.63px;
    	line-height: 15px;
      text-align: center;
      padding-top: 5px;
      padding-bottom: 5px;
    }

  }

  .subheader_name {
  	color: #4A4A4A;
    font-family: "LatoWeb";
  	font-size: 14px;
  	letter-spacing: 0.73px;
  	line-height: 17px;
    margin-bottom: 9px;
  }

  .subheader_value {
  	color: #115EA0;
    font-family: "LatoWeb";
  	font-size: 16px;
  	font-weight: 600;
  	letter-spacing: 0.84px;
  	line-height: 19px;
    margin-bottom: 9px;
  }

  .subheader_value-border {
    border-right: solid 1px #D8D8D8;
  }

  .subheader_container {
    margin-bottom: 15px;
  }

}

.exoneraton-search_title {
  color: #115EA0;
  font-family: "LatoWeb";
  font-size: 14px;
  font-style: italic;
  letter-spacing: 0.73px;
  line-height: 17px;
}

.exoneraton-col-padding {
  padding-top: 10px;
  .MuiFormControl-root-1 {
    margin-bottom: 5px;
    margin-top: 0px;
  }

  .MuiInputBase-root-36  {
    margin-top: 10px;
  }
}

.exoneraton-date-boder {
  border-right: solid 1px #D8D8D8;
}

.refund-search_container-col {
  min-height: 60px;
}

.refund-search_container {
  width: 100%;
  position: absolute;
  bottom: 0;
}

@media only screen and (max-width: 64em) {
  .exoneraton-date-boder {
    border-right: none;
  }
}
