.chat {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
  position: fixed;
  width: 244px;
  height: 186px;
  border-top-left-radius: 100%;
  bottom: 70px;
  right: 0;
  z-index: 1;

  .chat-new_options-cotainer {
    cursor: pointer;
    position: absolute;
    bottom: 10px;
    right: 10px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #04a98d;
    font-family: "LatoWeb";
    font-size: 12px;
    letter-spacing: 0.78px;
    line-height: 14px;
    img {
      border-radius: 50%;
      box-shadow: 0px 0px 8px -2px rgba(0, 0, 0, 0.3);
      width: 60px;
      height: 59px;
      margin-bottom: 5px;
      transition: 0.5s;
      &:hover {
        opacity: 0.7;
      }
    }
  }

  .chat-new_items-container {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #04a98d;
    font-family: "LatoWeb";
    font-size: 12px;
    letter-spacing: 0.78px;
    line-height: 14px;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
    img {
      width: 50px;
      height: 50px;
      margin-left: 5px;
      transition: 0.5s;
      &:hover {
        opacity: 0.7;
      }
    }
  }

  .chat-new_item-chat {
    position: absolute;
    bottom: 90px;
    right: 60px;
  }

  .chat-new_item-wp {
    position: absolute;
    bottom: 45px;
    right: 100px;
  }

  .is-open {
    visibility: visible;
    opacity: 1;
  }
}

.chat-home {
  position: absolute;
  z-index: 3;
  bottom: 70px;
  right: 0;
  .chat-new_items-container,
  .chat-new_options-cotainer {
    color: #ffffff;
  }
}

// @media(max-width: 1023px){

// }
