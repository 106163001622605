@keyframes fade {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.menu {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  overflow-y: auto;
  background-color: #4A4A4A;
  z-index: 9999;
  width: 320px;
  padding-bottom: 33px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 600ms, visibility 600ms;
        transition: opacity 600ms, visibility 600ms;

  .menu-header {
    background-color: #FFFFFF;
  }

  .close-container {
    display: flex;
    justify-content: flex-end;
  }

  .close {
    width: 30px;
    height: 30px;
    margin-top: 5px;
    outline: none;
    img {
      height: 15px;
    }
  }

  .menu-header-logo {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 38px;
  }

  .user-logo-container {
    height: 55px;
    width: 55px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .user-logo {
    height: 100%;
  }

  .menu-profile-name {
  	color: #FFFFFF;
    font-family: "LatoWeb";
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.71px;
    line-height: 19px;
    text-transform: capitalize;
  }

  .menu-profile-subtitle {
    color: #FFFFFF;
    font-family: "LatoWeb";
    font-size: 14px;
    letter-spacing: 0.9px;
    line-height: 17px;
    text-transform: capitalize;
  }

  .menu-item {
    padding: 18px 11px;
    border-top: solid 1px #606060;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }

  .menu-item-disabled {
    cursor: auto;
    opacity: 0.5;
    &:hover {
      opacity: 0.5;
    }
  }

  .menu-subheader {
    padding: 35px 11px;
  }

  .menu-item-text {
    margin: 0;
    color: #FFFFFF;
    font-family: "LatoWeb";
    font-size: 14px;
    letter-spacing: 0.73px;
    line-height: 17px;
    display: flex;
    align-items: center;

    svg {
      margin-right: 5px;
      font-size: 20px;
    }
  }
}
.menu-open {
  display: block;
  visibility: visible;
  opacity: 1;
  animation: fade 1s;
}

@media only screen and (max-width: 64em) {
  .menu {
    width: auto;
  }

}
