.header {
  background-color: #115EA0;
  padding: 0;
  align-items: stretch;
  .header-logo {
    height: 26px;
    margin: 22px 0;
    cursor: pointer;
  }

  .menu-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 100%;
    border-right: 1px solid #0088FD;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }

  .menu-icon {
    height: 20px;
  }
}

@media only screen and (max-width: 64em) {
  .header {
    .header-logo {
      height: 20px;
    }
  }
}
