.transactions {
  .card-title {
    color: #6d6d6d;
    font-family: 'LatoWeb';
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.84px;
    line-height: 19px;
  }

  .card-logo {
    display: flex;
    justify-content: flex-end;
    img {
      height: 30px;
      margin-bottom: 10px;
    }
  }

  .transactions-margin {
    margin-bottom: 43px;
  }

  .input-container {
    margin-bottom: 5px;
  }

  .transactions-body_key {
    color: #6d6d6d;
    font-family: 'LatoWeb';
    font-size: 16px;
    letter-spacing: 0.64px;
    line-height: 19px;
    margin-bottom: 16px;
  }

  .transactions-body_value {
    color: #115ea0;
    font-family: 'LatoWeb';
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.57px;
    line-height: 19px;
    text-align: right;
    margin-bottom: 16px;
    text-transform: capitalize;
  }

  .button-send-container {
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .add-check-container {
    margin-top: 15px;
  }

  .add-check {
    color: #115ea0;
    font-family: 'LatoWeb';
    font-size: 14px;
    letter-spacing: 1.02px;
    line-height: 26px;
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }

  .new-check-title {
    margin-top: 25px;
    color: #115ea0;
    font-family: 'LatoWeb';
    font-size: 16px;
    font-style: italic;
    letter-spacing: 1.17px;
    line-height: 19px;
  }
}

.tab-container {
  margin-top: 30px;
  margin-bottom: 10px;
}

.tab-text-selected {
  color: #115ea0;
  font-family: 'LatoWeb';
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.54px;
  line-height: 19px;
  text-align: center;
  height: 42px;
  border-bottom: solid 1px #115ea0;
  cursor: pointer;
}

.tab-check {
  color: #00b047;
  border-bottom: solid 1px #00b047;
}

.tab-text {
  color: #6d6d6d;
  font-family: 'LatoWeb';
  font-size: 16px;
  font-style: italic;
  font-weight: 600;
  letter-spacing: 0.84px;
  line-height: 19px;
  text-align: center;
  height: 42px;
  border-bottom: solid 1px #d8d8d8;
  cursor: pointer;
}

.tab-text-disabled {
  color: #d8d8d8;
  font-family: 'LatoWeb';
  font-size: 16px;
  font-style: italic;
  font-weight: 600;
  letter-spacing: 0.84px;
  line-height: 19px;
  text-align: center;
  height: 42px;
  border-bottom: solid 1px #d8d8d8;
}

.check-modal-title {
  color: #115ea0;
  font-family: 'LatoWeb';
  font-size: 14px;
  font-style: italic;
  letter-spacing: 0.73px;
  line-height: 19px;
  margin-bottom: 8px;
}

.check-modal-check-info {
  margin-bottom: 5px;
}
