.transactions {

  .card-sub-title-masive {
  	color: #4A4A4A;
    font-family: "LatoWeb";
  	font-size: 14px;
  	letter-spacing: 0.39px;
  	line-height: 19px;
  }

  .card-title-transaction {
  	color: #6D6D6D;
    font-family: "LatoWeb";
  	font-size: 16px;
  	font-weight: 600;
  	letter-spacing: 0.84px;
  	line-height: 19px;
  }

  .card-sub-title {
  	color: #115EA0;
    font-family: "LatoWeb";
  	font-size: 13px;
  	font-style: italic;
  	letter-spacing: 0.68px;
  	line-height: 19px;
  }

  .red {
    color: #F50B0B;
  }

  .set-pointer {
    cursor: pointer;
    input {
      cursor: pointer;
    }
  }


  .transactions-margin {
    margin-bottom: 30px;
  }

  .upload-transaction-icon {
    height: 55px;
    margin-left: 18px;
  }

  .upload-transaction-clock-icon {
    height: 64.8px;
    margin-left: 18px;
  }

  .option-title {
  	color: #115EA0;
    font-family: "LatoWeb";
  	font-size: 14px;
  	font-style: italic;
  	letter-spacing: 0.73px;
  	line-height: 19px;
    margin-bottom: 20px;
  }

  .option-description {
  	color: #6C6C6C;
    font-family: "LatoWeb";
  	font-size: 14px;
  	letter-spacing: 0.73px;
  	line-height: 19px;
    margin-bottom: 19px;
  }

  .option-link {
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }

  .errors-title {
  	color: #F50B0B;
    font-family: "LatoWeb";
  	font-size: 16px;
  	font-style: italic;
  	letter-spacing: 0.68px;
  	line-height: 19px;
    margin-bottom: 16px;
  }

  .success-title {
  	color: #115EA0;
    font-family: "LatoWeb";
  	font-size: 16px;
  	font-style: italic;
  	font-weight: bold;
  	letter-spacing: 0.94px;
  	line-height: 19px;
    margin-bottom: 16px;
    margin-top: 16px;
  }

  .approved-title {
  	color: #01B148;
    font-family: "LatoWeb";
  	font-size: 16px;
  	font-style: italic;
  	font-weight: bold;
  	letter-spacing: 0.68px;
  	line-height: 19px;
    margin-bottom: 16px;
    margin-top: 16px;
  }

  .results-table-container {
    overflow-x: auto;
    max-height: 400px;

    th {
    	color: #115EA0;
      font-family: "LatoWeb";
    	font-size: 14px;
    	font-weight: 600;
    	letter-spacing: 0.73px;
    	line-height: 13px;
    	text-align: center;
      vertical-align: middle;
      position: sticky;
      top: 0;
      background: white;
      z-index: 1;
    }

    .delete {
    	color: #F50B0B;
      font-family: "LatoWeb";
    	font-size: 10px;
    	font-weight: 600;
    	letter-spacing: 0.53px;
    	line-height: 13px;
    	text-align: center;
    }

    td {
    	color: #6D6D6D;
      font-family: "LatoWeb";
    	font-size: 14px;
    	letter-spacing: 0.73px;
    	line-height: 13px;
    }

    .error-value {
    	color: #F50B0B;
      font-family: "LatoWeb";
    	font-size: 14px;
    	letter-spacing: 0.73px;
    	line-height: 13px;
      margin-bottom: 10px;
    }

    .table-input {
      font-family: "LatoWeb";
      font-size: 14px;
      letter-spacing: 0.73px;
      line-height: 13px;
      border: none;
      color: #6D6D6D;
      max-width: 7em;
    }

    .error-value {
      .table-input {
        color: #F50B0B;
      }
    }

    .error-text {
    	color: #F50B0B;
      font-family: "LatoWeb";
    	font-size: 12px;
    	font-style: italic;
    	letter-spacing: 0.63px;
    	line-height: 13px;
      margin-bottom: 10px;
    }

    .denegate {
    	color: #F50B0B;
      font-family: "LatoWeb";
    	font-size: 14px;
    	letter-spacing: 0.73px;
    	line-height: 13px;
    }

    .cross-out {
      text-decoration: line-through;
    }

    .denegate-th {
    	color: #F53636;
      font-family: "LatoWeb";
    	font-size: 14px;
    	font-style: italic;
    	font-weight: bold;
    	letter-spacing: 0.73px;
    	line-height: 13px;
    	text-align: center;
    }

    .approved-th {
    	color: #00B047;
      font-family: "LatoWeb";
    	font-size: 14px;
    	font-style: italic;
    	font-weight: bold;
    	letter-spacing: 0.73px;
    	line-height: 13px;
    	text-align: center;
    }

    .approved-td {
    	color: #01B148;
      font-family: "LatoWeb";
    	font-size: 14px;
    	letter-spacing: 0.73px;
    	line-height: 13px;
    }
  }

  .close {
    width: 30px;
    height: 30px;
    margin-top: 5px;
    outline: none;
    img {
      height: 15px;
    }
  }

  .claim-upload-left {
    text-align: left;
  }

  .claim-upload-title {
  	color: #00B047;
    font-family: "LatoWeb";
  	font-size: 16px;
  	font-style: italic;
  	font-weight: 500;
  	letter-spacing: 0.84px;
  	line-height: 19px;
    margin-bottom: 20px;
  }

  .check-icon-container {
    display: flex;
    align-items: center;
  }

  .check-icon {
    height: 14px;
    margin-left: 5px;
  }

  .top-space {
    padding-top: 20px;
  }

  .no-check {
    margin-top: 20px;
  }

  .download-result-file {
  	color: #00B047;
    font-family: "LatoWeb";
  	font-size: 14px;
  	font-weight: 500;
  	letter-spacing: 0.39px;
  	line-height: 17px;
  	text-align: right;
    cursor: pointer;
    text-decoration: underline;
    border: none;
    background: transparent;
    outline: none;
    &:hover {
      opacity: 0.7;
    }
  }

  .download-result-file_container {
    justify-content: flex-end;
    display: flex;
  }

}
