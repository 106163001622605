.reserve {

  .card-title {
  	color: #4A4A4A;
    font-family: "LatoWeb";
  	font-size: 16px;
  	letter-spacing: 0.84px;
  	line-height: 19px;
    margin-bottom: 30px;
  }

  .reserve-margin {
    margin-bottom: 43px;
  }

  .resevation-icon {
    height: 54px;
  }

  .input-container {
    margin-bottom: 5px;
  }

  .reservation-loading-text {
    margin-top: 18px;
  	color: #4A4A4A;
    font-family: "LatoWeb";
  	font-size: 16px;
  	letter-spacing: 0.84px;
  	line-height: 19px;
  	text-align: center;
  }

  .reserve-body_key {
  	color: #6D6D6D;
    font-family: "LatoWeb";
  	font-size: 16px;
  	letter-spacing: 0.64px;
  	line-height: 19px;
    margin-bottom: 16px;
  }

  .reserve-body_value {
  	color: #115EA0;
    font-family: "LatoWeb";
  	font-size: 16px;
  	font-weight: bold;
  	letter-spacing: 0.57px;
  	line-height: 19px;
  	text-align: right;
    margin-bottom: 16px;
    text-transform: capitalize;
  }

  .reserve-contract_days {
  	color: #115EA0;
    font-family: "LatoWeb";
  	font-size: 16px;
  	letter-spacing: 1.17px;
  	line-height: 19px;
  }

  .reserve-contract_days-terms {
  	color: #6D6D6D;
    font-family: "LatoWeb";
  	font-size: 12px;
  	font-style: italic;
  	letter-spacing: 0.63px;
  	line-height: 14px;
  }

  .reserve-contract_days-terms-bold {
  	font-weight: bold;
  }

  .reserve-input-form {
    margin-top: 30px;
    margin-bottom: 40px;
  }

  .reserve-aproved-button-container {
    margin-top: 40px;
    margin-bottom: 20px;
  }


}

.resevation-icon-denegate {
  height: 104px;
}

.confirm-reserve-title {
	color: #FFFFFF;
  font-family: "LatoWeb";
	font-size: 18px;
	font-weight: 600;
	letter-spacing: 1.71px;
	line-height: 22px;
}

.confirm-reserve_body-key {
	color: #4A4A4A;
  font-family: "LatoWeb";
	font-size: 14px;
	letter-spacing: 0.73px;
	line-height: 19px;
  margin-bottom: 15px;
}

.confirm-reserve_body-value {
	color: #4A4A4A;
  font-family: "LatoWeb";
	font-size: 14px;
	font-weight: bold;
	letter-spacing: 0.73px;
	line-height: 19px;
	text-align: right;
  margin-bottom: 15px;
  text-transform: capitalize;
}

.confirm-reserve_body-description {
  padding-top: 20px;
  margin-bottom: 20px;
}

.reserve-aproved-title {
	color: #115EA0;
  font-family: "LatoWeb";
	font-size: 16px;
	font-weight: 600;
	letter-spacing: 0.77px;
	line-height: 27px;
	text-align: center;
  margin-top: 30px;
}

.not-reservation-text {
  color: #6C6C6C;
  font-family: "LatoWeb";
  font-size: 16px;
  letter-spacing: 0.84px;
  line-height: 22px;
  text-align: center;
  margin-top: 10px;
}

.not-reservation-text_bold {
  font-weight: 600;
}

.reserve-aproved-button-container {
  margin-top: 40px;
  margin-bottom: 20px;
}
