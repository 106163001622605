@keyframes spinner {
  to {transform: rotate(360deg);}
}

.loading {
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 9;

  img {
    animation: spinner .95s linear infinite;
  }

}
