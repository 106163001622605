.exoneration {

  .card-sub-title {
    color: #115EA0;
    font-family: "LatoWeb";
    font-size: 13px;
    font-style: italic;
    letter-spacing: 0.68px;
    line-height: 19px;
  }

  .red {
    color: #F50B0B;
  }

  .card-title {
  	color: #115EA0;
  	font-family: "LatoWeb";
  	font-size: 16px;
  	letter-spacing: 0.84px;
  	line-height: 19px;
    margin-bottom: 23px;

    &.green {
      color: #00B047;
    }
  }

  .card-description {
  	color: #4A4A4A;
    font-family: "LatoWeb";
  	font-size: 14px;
  	letter-spacing: 0.73px;
  	line-height: 19px;
    margin-bottom: 23px;
  }

  .icon-container {
    margin-bottom: 23px;
    img {
      height: 55px;
    }
  }

  .exoneration-margin {
    margin-bottom: 23px;
  }

  .exoneration-table-container {
    margin-bottom: 40px;
    margin-top: 40px;
    height: 250px;
    overflow: auto;

    th {
    	color: #115EA0;
      font-family: "LatoWeb";
    	font-size: 14px;
    	font-weight: 600;
    	letter-spacing: 0.73px;
    	line-height: 13px;
      padding-top: 23px;
      padding-bottom: 23px;
      text-align: center;
    }

    .notice-td-container {
      display: flex;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
        text-decoration: underline;
      }
    }

    .notice-td {
      color: #00B047;
      font-family: "LatoWeb";
      font-size: 14px;
      font-style: italic;
      letter-spacing: 0.73px;
      line-height: 13px;
    }

    .link-arrow {
      margin-left: 5px;
    }

    td {
    	color: #6D6D6D;
      font-family: "LatoWeb";
    	font-size: 14px;
    	letter-spacing: 0.73px;
    	line-height: 13px;
      vertical-align: middle;
    }

    .bill {
      color: #115EA0;
    }

    .check {
      color: #239175;
    }

    label {
      margin: 0;
    }

    .partial {
      text-decoration: underline;
      cursor: pointer;
      &:hover {
        opacity: 0.7;

      }
    }
  }

  .approved-title {
  	color: #01B148;
    font-family: "LatoWeb";
  	font-size: 16px;
  	font-style: italic;
  	font-weight: bold;
  	letter-spacing: 0.68px;
  	line-height: 19px;
    margin-bottom: 16px;
    margin-top: 16px;
  }

  .results-table-container {
    overflow-x: auto;
    max-height: 400px;

    th {
    	color: #115EA0;
      font-family: "LatoWeb";
    	font-size: 14px;
    	font-weight: 600;
    	letter-spacing: 0.73px;
    	line-height: 13px;
    	text-align: center;
      vertical-align: middle;
      position: sticky;
      top: 0;
      background: white;
      z-index: 1;
    }

    .delete {
    	color: #F50B0B;
      font-family: "LatoWeb";
    	font-size: 10px;
    	font-weight: 600;
    	letter-spacing: 0.53px;
    	line-height: 13px;
    	text-align: center;
    }

    td {
    	color: #6D6D6D;
      font-family: "LatoWeb";
    	font-size: 14px;
    	letter-spacing: 0.73px;
    	line-height: 13px;
    }

    .error-value {
    	color: #F50B0B;
      font-family: "LatoWeb";
    	font-size: 14px;
    	letter-spacing: 0.73px;
    	line-height: 13px;
      margin-bottom: 10px;
    }

    .table-input {
      font-family: "LatoWeb";
      font-size: 14px;
      letter-spacing: 0.73px;
      line-height: 13px;
      border: none;
      color: #6D6D6D;
      max-width: 7em;
    }

    .error-text {
    	color: #F50B0B;
      font-family: "LatoWeb";
    	font-size: 12px;
    	font-style: italic;
    	letter-spacing: 0.63px;
    	line-height: 13px;
      margin-bottom: 10px;
    }

    .denegate {
    	color: #F50B0B;
      font-family: "LatoWeb";
    	font-size: 14px;
    	letter-spacing: 0.73px;
    	line-height: 13px;
    }

    .cross-out {
      text-decoration: line-through;
    }

    .denegate-th {
    	color: #F53636;
      font-family: "LatoWeb";
    	font-size: 14px;
    	font-style: italic;
    	font-weight: bold;
    	letter-spacing: 0.73px;
    	line-height: 13px;
    	text-align: center;
    }

    .approved-th {
    	color: #00B047;
      font-family: "LatoWeb";
    	font-size: 14px;
    	font-style: italic;
    	font-weight: bold;
    	letter-spacing: 0.73px;
    	line-height: 13px;
    	text-align: center;
    }

    .approved-td {
    	color: #01B148;
      font-family: "LatoWeb";
    	font-size: 14px;
    	letter-spacing: 0.73px;
    	line-height: 13px;
    }
  }

  .errors-title {
  	color: #F50B0B;
    font-family: "LatoWeb";
  	font-size: 16px;
  	font-style: italic;
  	letter-spacing: 0.68px;
  	line-height: 19px;
    margin-bottom: 16px;
  }

  .success-title {
  	color: #115EA0;
    font-family: "LatoWeb";
  	font-size: 16px;
  	font-style: italic;
  	font-weight: bold;
  	letter-spacing: 0.94px;
  	line-height: 19px;
    margin-bottom: 16px;
    margin-top: 16px;
  }

  .close-table {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .option-link {
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }

  .download-result-file {
    color: #00B047;
    font-family: "LatoWeb";
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.39px;
    line-height: 17px;
    text-align: right;
    cursor: pointer;
    text-decoration: underline;
    border: none;
    background: transparent;
    outline: none;
    &:hover {
      opacity: 0.7;
    }
  }

  .download-result-file_container {
    justify-content: flex-end;
    display: flex;
  }

}

.partial-modal-title {
  color: #FFFFFF;
  font-family: "LatoWeb";
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1.71px;
  line-height: 22px;
}

.partial_info-key {
	color: #4A4A4A;
  font-family: "LatoWeb";
	font-size: 14px;
	letter-spacing: 0.73px;
	line-height: 19px;
}

.partial_info-value {
	color: #4A4A4A;
  font-family: "LatoWeb";
	font-size: 14px;
	font-weight: bold;
	letter-spacing: 0.73px;
	line-height: 19px;
	text-align: right;
  text-transform: capitalize;
}

.partial_info-container {
  margin-bottom: 14px;
}

.partial_info-separator {
  width: 100%;
}

.partial_info-button-container {
  margin-bottom: 26px;
  margin-top: 36px;
}

.partial_info-end-button-container {
  margin-top: 36px;
}

.partial_info-center {
  display: flex;
  align-items: center;
}

.exoneraton-search_name-center {
  align-items: center;
  justify-content: space-between;
}

.exoneraton-search_name {
  color: #115EA0;
  font-family: "LatoWeb";
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.84px;
  line-height: 19px;
  text-transform: capitalize;
}

.exoneraton-search_document {
  color: #4A4A4A;
  font-family: "LatoWeb";
  font-size: 16px;
  letter-spacing: 0.84px;
  line-height: 19px;
}

.exoneraton-search_name-container {
  display: flex;
  align-items: center;
}
