.documents {

  .documents-main-card_title {
  	color: #4A4A4A;
    font-family: "LatoWeb";
    font-size: 16px;
    letter-spacing: 0.84px;
    line-height: 19px;
  }

  .documents-main-card_sub-title {
    color: #115EA0;
    font-family: "LatoWeb";
    font-size: 13px;
    font-style: italic;
    letter-spacing: 0.68px;
    line-height: 19px;
  }

  .documents-main-card_divider {
    margin-top: 29px;
    margin-bottom: 37px;
  }

  .box-card-container {
    margin-bottom: 20px;
  }

  .box-card {
    background-color: #FFFFFF;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
    padding: 10px 15px;
  }

  .documents_box-download {
    color: #115EA0;
    font-family: "LatoWeb";
    font-size: 9px;
    letter-spacing: 0.47px;
    line-height: 15px;
    padding: 0 7px;
    display: flex;
    align-items: flex-end;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }

  .documents_box-number {
    color: #115EA0;
    font-family: "LatoWeb";
    font-size: 30px;
    font-weight: 300;
    letter-spacing: 1.57px;
    line-height: 36px;
    padding-bottom: 3px;
    border-bottom: solid 1px #CCCCCC;
  }

  .documents_box-title {
  	color: #115EA0;
    font-family: "LatoWeb";
  	font-size: 16px;
  	letter-spacing: 0.84px;
  	line-height: 19px;
  }

  .documents_box-description {
  	color: #717171;
    font-family: "LatoWeb";
  	font-size: 12px;
  	letter-spacing: 0.63px;
  	line-height: 15px;
  }

  .documents_box-number-container {
    padding: 0 13px 7px 13px;
  }

  .documents_box-title-container {
    padding: 0;
  }

}
