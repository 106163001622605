.manage-users {

  .card-title {
  	color: #4A4A4A;
    font-family: "LatoWeb";
  	font-size: 16px;
  	letter-spacing: 0.84px;
  	line-height: 19px;
  }

  .manage-users-margin {
    margin-bottom: 30px;
  }

  .manage-users-margin-top {
    margin-top: 20px;
  }

  .manage-users_user-key-container {
    margin-bottom: 15px;
  }

  .manage-users_user-key {
  	color: #115EA0;
    font-family: "LatoWeb";
  	font-size: 14px;
  	letter-spacing: 0.84px;
  	line-height: 17px;
  }

  .manage-users_user-value {
  	color: #717171;
    font-family: "LatoWeb";
  	font-size: 14px;
  	letter-spacing: 0.63px;
  	line-height: 17px;
  }

  .disabled-color {
    color: #CBCBCB;
  }

  .manage-users_info-container {
    padding-top: 15px;
    border: solid 1px #E1E2E2;
  }

  .manage-users_info-second-container {
    padding-top: 15px;
    border: solid 1px #E1E2E2;
    border-top: none;
    justify-content: flex-end;
  }

  .activate {
  	color: #115EA0;
    font-family: "LatoWeb";
  	font-size: 14px;
  	letter-spacing: 1.24px;
  	line-height: 17px;
  	text-align: center;
    cursor: pointer;
    text-decoration: underline;
    margin-bottom: 15px;
    &:hover {
      opacity: 0.7;
    }
  }

  .disable {
  	color: #ED1515;
    font-family: "LatoWeb";
  	font-size: 14px;
  	letter-spacing: 1.17px;
  	line-height: 17px;
  	text-align: center;
    margin-bottom: 15px;
    cursor: pointer;
    text-decoration: underline;
    &:hover {
      opacity: 0.7;
    }
  }

  .disabled-button-color {
    color: #4A4A4A;
  }

  .manage-users_user {
    margin-bottom: 28px;
  }

  .manage-users_change-password {
  	height: 15px;
  	width: 262px;
  	color: #D53D4F;
    font-family: "LatoWeb";
  	font-size: 12px;
  	font-style: italic;
  	letter-spacing: 1.07px;
  	line-height: 15px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
  }

  .alert-icon {
    height: 22px;
    margin-right: 10px;
  }

  .manage-users_user-image {
    height: 63px;
    width: 63px;
    border-radius: 50%;
    object-fit: cover;
  }

}

.modal-form-title {
	color: #115EA0;
  font-family: "LatoWeb";
	font-size: 16px;
	font-style: italic;
	letter-spacing: 0.84px;
	line-height: 19px;
  margin-bottom: 25px;
}

.modal-form-buttons {
  margin-top: 40px;
  margin-bottom: 20px;
}
