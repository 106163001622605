.terms {

  .terms-main-card_title {
	   color: #115EA0;
     font-family: "LatoWeb";
     font-size: 16px;
     letter-spacing: 0.84px;
     line-height: 19px;
  }

  .terms-main-card_divider {
    margin-top: 29px;
    margin-bottom: 37px;
  }

  .terms-main-card_body-text {
  	color: #4A4A4A;
    font-family: "LatoWeb";
  	font-size: 12px;
  	letter-spacing: 0.7px;
  	line-height: 15px;
  	text-align: justify;
  }

  .terms-main-card_button-container {
    margin-top: 19px;
  }

  .terms-main-card_body-text {
    h1 {
      text-indent: unset !important;
      margin-left: 30px!important;
    }
  }


}
