hr {
  margin: 0;
}

.App {
}

.modal-close-button-container {
  position: relative;
  .modal-close-button {
    position: absolute !important;
    right: 0;
  }
}

.info-img {
  width: 100%;
}

.full-screen {
  height: calc(100vh - 64px);
}

.login-scroll {
  overflow-y: auto;
}

.center-horizontally {
  display: flex;
  justify-content: center;
}

.center-vertically {
  display: flex;
  align-items: center;
}

.right-hotizontally {
  display: flex;
  justify-content: flex-end;
}

.btn {
  font-family: "LatoWeb";
}

.button-send {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1.33px;
  line-height: 19px;
  background-color: #00b047;
  padding: 15px 10px;
  &:hover {
    opacity: 0.8;
  }
  &:disabled,
  &[disabled] {
    opacity: 1;
    background-color: #9b9b9b;
    border-color: #9b9b9b;
  }
}

.button-primary {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1.33px;
  line-height: 19px;
  background-color: #115ea0;
  padding: 15px 10px;
  &:hover {
    opacity: 0.8;
  }
  &:disabled,
  &[disabled] {
    opacity: 1;
    background-color: #9b9b9b;
    border-color: #9b9b9b;
  }
}

.button-primary-link {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1.33px;
  line-height: 19px;
  color: #115ea0;
  padding: 15px 10px;
  &:hover {
    opacity: 0.8;
  }
  &:disabled,
  &[disabled] {
    opacity: 1;
    color: #9b9b9b;
  }
}

.button-link {
  color: #ffffff;
  font-size: 13px;
  font-weight: 300;
  letter-spacing: 1.44px;
  line-height: 15px;
  text-decoration: underline;
  &:hover {
    color: #ffffff;
    opacity: 0.8;
  }
}

.page-body {
  width: 60%;
  padding: 0 15px;
  margin-top: 27px;
}

.user-logo-container {
  height: 65px;
  width: 65px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-logo {
  width: 100%;
  &:before {
    content: " ";
    display: block;
    height: 65px;
    width: 65px;
    background-image: url("assets/logos/user-logo.svg");
  }
}

.user-logo-second {
  width: 100%;
  &:before {
    content: " ";
    display: block;
    height: 55px;
    width: 55px;
    background-image: url("assets/logos/user-logo.svg");
    background-size: 55px 55px;
  }
}

.company-logo-container {
  overflow: hidden;
}

.company-logo {
  height: 70px;
  &:before {
    content: " ";
    display: block;
    height: 70px;
    width: 118px;
    background-image: url("assets/logos/defaultcompanylogo.svg");
    background-size: 118px 70px;
  }
}

.no-padding {
  padding: 0;
}

.height-100 {
  height: 100%;
}

.login-input {
  font-family: "LatoWeb";
  font-size: 15px;
  color: #ffffff;

  label {
    color: #5a8fbd !important;
    font-family: "LatoWeb";
    font-size: 12px;
    letter-spacing: 0.88px;
    line-height: 15px;
  }

  p {
    font-family: "LatoWeb";
  }

  div {
    &:before {
      border-color: #ffffff;
    }

    &:after {
      border-color: #ffffff;
    }
    &:hover {
      &:before {
        border-bottom: 2px solid #ffffff !important;
      }
    }
  }

  // & > div {
  //   background: #FFFFFF;
  // }

  .login-icon {
    color: #fff;
    padding: 0;
  }

  input {
    color: #fff;
    font-family: "LatoWeb";
    font-size: 15px;
  }
}

.home-input {
  font-family: "LatoWeb";
  font-size: 15px;
  color: #4a4a4a;

  label {
    color: #ababab;
    font-family: "LatoWeb";
    font-size: 12px;
    letter-spacing: 0.88px;
    line-height: 15px;
  }

  p {
    font-family: "LatoWeb";
  }

  div {
    font-family: "LatoWeb";
    font-size: 15px;
    color: #4a4a4a;

    &:before {
      // border-color: #115EA0;
    }

    &:after {
      // border-color: #ABABAB;
    }
    &:hover {
      &:before {
        // border-bottom: 2px solid #115EA0!important;
      }
    }
  }

  input {
    font-family: "LatoWeb";
    font-size: 15px;
    color: #4a4a4a;
  }
}

.input-date {
  margin-top: 0px !important;
  margin-bottom: 8px !important;
}

.login-input-container {
  margin-top: 5px;
}

.modal-content {
  border-radius: 0px;
  border: none;
}

.modal-header {
  background-color: #115ea0;
  border-radius: 0;
}

.terms-modal-title {
  color: #ffffff;
  font-family: "LatoWeb";
  font-size: 22px;
  font-style: italic;
  font-weight: 900;
  letter-spacing: 0.87px;
  line-height: 27px;
  text-align: center;
  width: 100%;
}

.error-modal_button-container {
  margin-top: 30px;
}

.location-modal-title {
  color: #ffffff;
  font-family: "LatoWeb";
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1.71px;
  line-height: 22px;
  text-align: center;
  width: 100%;
}

.terms-modal-body {
  padding: 35px;
}

.location-modal-body {
  padding: 15px;
}

.location-modal_done-button {
  margin-top: 30px;
}

.terms-modal_body-description {
  color: #4a4a4a;
  font-family: "LatoWeb";
  font-size: 14px;
  letter-spacing: 0.73px;
  line-height: 21px;
  text-align: center;
  margin-bottom: 6px;
  padding-left: 55px;
  padding-right: 55px;
}

.terms-modal_body-form {
  margin-bottom: 30px;
  display: flex;
  justify-content: center;

  label {
    margin: 0;
  }

  .MuiButtonBase-root-25 {
    padding: 0 0 0 6px;
  }

  .MuiFormControlLabel-label-8 {
    color: #4a4a4a;
    font-family: "LatoWeb";
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.73px;
    line-height: 19px;
    text-align: center;
  }
}

.goback-text {
  color: #115ea0;
  font-family: "LatoWeb";
  font-size: 12px;
  letter-spacing: 0.63px;
  line-height: 15px;
}

.goback-icon {
  height: 10px;
  margin-right: 4px;
}

.goback-container {
  padding: 0;
  margin-bottom: 30px;
}

.goback-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
}

.general-main-card {
  position: relative;
  padding: 10px 15px 20px 15px;
  border: solid 1px #d8d8d8;
  margin-bottom: 40px;
  min-height: calc(100vh - 282px);
}

.date-picker-container {
  .MuiFormControl-root-1 {
    margin: 8px 0px 4px 0px;
  }

  .MuiInputBase-root-43 {
    height: 29px;
  }

  .calendar-icon {
    margin-bottom: 6px;
    margin-right: 6px;
  }
}

.button-outline {
  background: transparent;
}

.search-input-container {
  border-bottom: solid 1px #ababab;
  height: 100%;
  display: flex;

  span {
    display: flex;
  }

  input {
    height: 100%;
    width: 100%;
    border: none;
    color: #4a4a4a;
    font-family: "LatoWeb";
    font-size: 14px;
    letter-spacing: 0.57px;
    line-height: 17px;
    outline: none;
    padding-left: 6px;
    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #ababab;
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #ababab;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #ababab;
    }
  }
}

.aproved-icon-container {
  margin-top: 50px;
  margin-bottom: 22px;
  img {
    height: 54px;
  }
}

.aproved-title {
  color: #115ea0;
  font-family: "LatoWeb";
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.87px;
  line-height: 27px;
  text-align: center;
  margin-bottom: 27px;
}

.aproved-description {
  color: #6c6c6c;
  font-family: "LatoWeb";
  font-size: 16px;
  letter-spacing: 0.84px;
  line-height: 22px;
  text-align: center;
  margin-bottom: 27px;
  .aproved-description-bold {
    font-weight: 600;
  }
}

.aproved-button-container {
  margin-bottom: 27px;
}

@media only screen and (min-width: 64em) {
  .hide-desktop {
    display: none !important;
  }

  .right-hotizontally-desktop {
    display: flex;
    justify-content: flex-end;
  }
}

@media only screen and (max-width: 64em) {
  .hide-mobile {
    display: none !important;
  }

  .full-screen {
    min-height: calc(100vh - 64px);
    height: auto;
  }

  .page-body {
    width: 100%;
  }

  .header-logo {
    height: 20px;
  }

  .center-horizontally-mobile {
    display: flex;
    justify-content: center;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.modal-study-title {
  width: 100%;
  text-align: center;
}
