.home {
  .home-main-card {
    padding: 40px 15px 20px 15px;
    border: solid 1px #d8d8d8;
  }

  .home-second-card {
    padding: 40px 15px 20px 15px;
    margin-bottom: 40px;
    border-left: solid 1px #d8d8d8;
    border-right: solid 1px #d8d8d8;
    border-bottom: solid 1px #d8d8d8;
  }

  .box-card-container {
    margin-bottom: 30px;
  }

  .box-card {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    height: 100%;
  }

  .box-card-auto {
    height: auto;
  }

  .home-options-title {
    font-family: 'LatoWeb';
    color: #6d6d6d;
    font-size: 16px;
    letter-spacing: 0.84px;
    padding: 15px 10px;
  }

  .home-option {
    height: 100%;
    padding: 15px 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }

  .action-icon {
    height: 47px;
  }

  .action-icon-transparency {
    height: 52px;
  }

  .home-option-title {
    font-family: 'LatoWeb';
    color: #115ea0;
    font-size: 16px;
    letter-spacing: 0.84px;
    line-height: 19px;
    margin-left: 18px;
    width: 100%;
  }

  .home-option-disabled {
    height: 100%;
    padding: 15px 10px;
    display: flex;
    align-items: center;
    .home-option-title {
      color: #c7c8c7;
    }
    .home-option-description {
      color: #c7c8c7;
    }
  }

  .arrow {
    float: right;
  }

  .home-option-arrow {
    width: 1em;
  }

  .home-option-description {
    margin-top: 6px;
    font-family: 'LatoWeb';
    color: #717171;
    font-size: 12px;
    letter-spacing: 0.63px;
    line-height: 13px;
  }

  .home_transaction-form-title {
    color: #4a4a4a;
    font-family: 'LatoWeb';
    font-size: 14px;
    letter-spacing: 0.39px;
    line-height: 19px;
    margin-bottom: 30px;
  }

  .home_transaction-form-button {
    margin-top: 40px;
  }

  .home_transaction-form-button-second {
    // margin-top: 10px;
    margin-top: 40px;
  }

  .home_loading-container {
    position: relative;
    height: 73px;
    margin-bottom: 18px;
  }

  .home_loading-text {
    color: #717171;
    font-family: 'LatoWeb';
    font-size: 14px;
    letter-spacing: 0.73px;
    line-height: 17px;
    text-align: center;
  }

  .home_query-key {
    color: #4a4a4a;
    font-family: 'LatoWeb';
    font-size: 14px;
    letter-spacing: 0.56px;
    line-height: 17px;
    margin-bottom: 5px;
  }

  .home_query-value {
    color: #115ea0;
    font-family: 'LatoWeb';
    font-size: 16px;
    letter-spacing: 0.57px;
    line-height: 19px;
    display: flex;
    justify-content: flex-start;
    // text-transform: capitalize;
    margin-bottom: 20px;
  }

  .capitalize {
    text-transform: capitalize;
  }

  .home_query-row {
    padding-top: 10px;
    padding-bottom: 9px;
    // border-bottom: solid 1px #ABABAB;
  }

  .home_query-row-no-border  {
    padding-top: 10px;
    padding-bottom: 9px;
  }

  .red-value {
    color: #f12d07;
  }

  .home_query-unregistered {
    color: #6c6c6c;
    font-family: 'LatoWeb';
    font-size: 15px;
    letter-spacing: 0.79px;
    line-height: 18px;
    margin-bottom: 30px;
  }

  .home_query-unregistered-bold {
    // color: #115EA0;
    font-weight: 600;
  }

  .home_query-unregistered-second {
    color: #6c6c6c;
    font-family: 'LatoWeb';
    font-size: 15px;
    letter-spacing: 0.79px;
    line-height: 18px;
    display: flex;
    &::before {
      content: '\2022'; /* Add content: \2022 is the CSS Code/unicode for a bullet */
      color: #115ea0; /* Change the color */
      font-size: 10px;
      // font-weight: bold; /* If you want it to be bold */
      display: inline-block; /* Needed to add space between the bullet and the text */
      width: 1em; /* Also needed for space (tweak if needed) */
      margin-left: -1em; /* Also needed for space (tweak if needed) */
      margin-bottom: 5px;
    }
  }

  .space-bold {
    margin-left: 5px;
  }

  .tab-container-home {
    margin-bottom: 30px;
  }
}

.terms-link-modal {
  color: #4a4a4a;
  font-family: 'LatoWeb';
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.73px;
  line-height: 19px;
  text-align: right;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.query-code-title {
  color: #ffffff;
  font-family: 'LatoWeb';
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1.71px;
  line-height: 22px;
}

.query-code-description {
  color: #4a4a4a;
  font-family: 'LatoWeb';
  font-size: 16px;
  letter-spacing: 0.84px;
  line-height: 20px;
  text-align: center;
  margin-bottom: 30px;
}

.home_query-unregistered-bold {
  // color: #115EA0;
  font-weight: 600;
}

.query-code-buttons {
  margin-top: 30px;
}
