@keyframes spinner {
  to {transform: rotate(360deg);}
}

.reservations {

  .card-title {
  	color: #4A4A4A;
    font-family: "LatoWeb";
  	font-size: 16px;
  	letter-spacing: 0.84px;
  	line-height: 19px;
    margin-bottom: 30px;
  }

  .reservations-margin {
    margin-bottom: 23px;
  }

  .reservations-table-container {
    margin-bottom: 40px;
    margin-top: 20px;
    height: 250px;
    overflow: auto;

    th {
    	color: #115EA0;
      font-family: "LatoWeb";
    	font-size: 14px;
    	font-weight: 600;
    	letter-spacing: 0.73px;
    	line-height: 13px;
      padding-top: 23px;
      padding-bottom: 23px;
      padding-left: 20px;
    }

    td {
      vertical-align: middle;
      padding-left: 20px;
    	color: #6D6D6D;
      font-family: "LatoWeb";
    	font-size: 14px;
    	letter-spacing: 0.73px;
    	line-height: 13px;
    }

    .red {
      color: #F50B0B;
    }

    .bill {
      color: #115EA0;
    }

    .check {
      color: #00B047;
	    font-style: italic;
    }

    .selectable {
      cursor: pointer;
      &:hover {
        opacity: 0.7;
        text-decoration: underline;
      }
    }

    label {
      margin: 0;
    }

    .partial {
      text-decoration: underline;
      cursor: pointer;
      &:hover {
        opacity: 0.7;

      }
    }

    .link-arrow {
      margin-left: 5px;
    }
  }

  .reservations-description-container {
    display: flex;
    align-items: center;
  }

  .reservations-description {
  	color: #4A4A4A;
    font-family: "LatoWeb";
  	font-size: 14px;
  	letter-spacing: 0.73px;
  	line-height: 19px;
    margin-left: 14px;
  }

  .input-container {
    margin-bottom: 5px;
  }

  .reservation-loading {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      animation: spinner .95s linear infinite;
    }

  }

  .not-reservation-text {
    margin-top: 20px;
    margin-bottom: 20px;
  	color: #6C6C6C;
    font-family: "LatoWeb";
  	font-size: 16px;
  	letter-spacing: 0.84px;
  	line-height: 22px;
  	text-align: center;
  }

  .not-reservation-text_bold {
    color: #0064A3;
    font-weight: 600;
  }

}

.reservation-loading-text {
  margin-top: 18px;
  color: #4A4A4A;
  font-family: "LatoWeb";
  font-size: 16px;
  letter-spacing: 0.84px;
  line-height: 19px;
  text-align: center;
}

.resevation-icon {
  height: 49px;
}
