.client-study {

  .client-study_address-error {
    font-family: "LatoWeb";
    margin: 0;
    font-size: 0.75rem;
    text-align: left;
    margin-top: 4px;
    min-height: 1em;
    line-height: 1em;
    color: #f44336;
  }

  .check-digit {
    input {
      text-align: center;
    }
  }

  .client-study_terms-container {
    margin-bottom: 20px;
  }

  .client-study_terms-checkbox {
    svg:first-child {
      color: #36DF58;
    }
  }

  .client-study_terms-checkbox-container {
    padding-right: 0;
  }

  .client-study_terms-text {
    color: #4A4A4A;
    font-family: "LatoWeb";
    font-size: 12px;
    letter-spacing: 0.88px;
    line-height: 15px;
    padding-left: 0;
  }

  .public-client-study_terms-checkbox-container {
    padding-right: 0;
  }

  .public-client-study_terms-text {
    font-family: "LatoWeb";
    font-size: 11px;
    font-weight: 500;
    letter-spacing: 0.45px;
    line-height: 16px;
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;
    align-content: center;

    .first {
      margin-right: 5px;
    }

    a {
      color: #115EA0;
      font-family: "LatoWeb";
      font-size: 11px;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }

    .public-client-study_terms-text-nolink {
      margin: 0 5px;
      &:first-child {
        margin-left: 0;
      }
    }

  }

  .general-main-card-no-border {
    border: none;
  }

  .partner-list-container {
    margin-bottom: 20px;
  }

  .login-input-container {
    height: 55.5px;
  }

  .date-picker-container {
    div:first-child {
      margin-top: 8px;
      margin-bottom: 4px;
    }
  }

  .address-other {
    width: 100%;
  }

  .address-padding {
    padding: 0 5px;
  }

  .address-padding-left {
    padding-left: 5px;
  }

  .client-study_address-select {
    width: 55px;
    div:first-child {
      div:first-child {
        padding-right: 13px;
        padding-bottom: 5px;
        font-family: "LatoWeb";
        font-size: 15px;
        color: #4A4A4A;
      }
    }
    svg {
      right: -5px;
    }
  }

  .client-study_address-title {
    color: #ABABAB;
    font-family: "LatoWeb";
    font-size: 12px;
    letter-spacing: 0.88px;
    line-height: 15px;
    margin-top: 8px;
  }

  .client-study_points-of-sale {
    color: #4A4A4A;
    font-family: "LatoWeb";
    font-size: 12px;
    letter-spacing: 0.88px;
    line-height: 15px;
    margin-top: 8px;
  }

  .client-study_mode-helper {
    text-align: right;
    color: #ABABAB;
    font-family: "LatoWeb";
    font-size: 12px;
    letter-spacing: 0.88px;
    line-height: 15px;
  }

  .sub-container {
    padding-left: 40px;
    padding-right: 40px;
  }

  .client-study-main-card_title {
  	color: #4A4A4A;
    font-family: "LatoWeb";
  	font-size: 16px;
  	letter-spacing: 0.84px;
  	line-height: 19px;
  }

  .client-study-main-card_radio-container {
    display: flex;
    justify-content: center;
  }

  .client-study-main-card_radio {
    margin-bottom: 0;
    span:last-child {
      font-family: "LatoWeb";
      font-size: 15px;
      color: #4A4A4A;
      font-style: normal;
    }

  }

  .client-study-main-card_link {
  	color: #00B047;
    font-family: "LatoWeb";
  	font-size: 14px;
  	letter-spacing: 0.73px;
  	line-height: 17px;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }

  .client-study-main-card_link-container {
    display: flex;
    justify-content: flex-end;
  }

  .client-study-main-card_divider {
    margin-top: 29px;
    margin-bottom: 29px;
  }

  .client-study-main-card_body-text {
  	color: #4A4A4A;
    font-family: "LatoWeb";
  	font-size: 12px;
  	letter-spacing: 0.7px;
  	line-height: 15px;
  	text-align: justify;
  }

  .client-study-main-card_button-container {
    margin-top: 35px;
    button {
      margin-bottom: 10px;
    }
  }

  .client-study-main-card_body-title {
  	color: #115EA0;
    font-family: "LatoWeb";
  	font-size: 16px;
  	font-style: italic;
  	letter-spacing: 0.84px;
  	line-height: 19px;
    margin-top: 25px;
  }

  .client-study-main-card_bottom-title {
  	color: #115EA0;
    font-family: "LatoWeb";
  	font-size: 16px;
  	font-weight: 600;
  	letter-spacing: 0.74px;
  	line-height: 19px;
  	text-align: center;
    margin-top: 40px;
    margin-bottom: 28px;
  }

  .client-study-main-card_bottom-terms {
  	color: #4A4A4A;
    font-family: "LatoWeb";
  	font-size: 12px;
  	letter-spacing: 0.56px;
  	line-height: 15px;
  	text-align: center;
    margin-bottom: 40px;
    cursor: pointer;
    text-decoration: underline;
    &:hover {
      opacity: 0.7;
    }
  }

  .link-arrow {
  	height: 11px;
    margin-left: 5px;
  }

  .client-study_denegate-title {
  	color: #115EA0;
    font-family: "LatoWeb";
  	font-size: 18px;
  	font-weight: 600;
  	letter-spacing: 0.87px;
  	line-height: 27px;
  	text-align: center;
    margin-bottom: 25px;
  }

  .client-study_denegate-description {
  	color: #6C6C6C;
    font-family: "LatoWeb";
  	font-size: 16px;
  	letter-spacing: 0.84px;
  	line-height: 22px;
  	text-align: center;
    margin-bottom: 45px;
  }

  .client-study_aproved-description {
    margin-bottom: 25px;
  }

  .client-study_aproved-time {
    color: #9b9b9b;
    font-family: "LatoWeb";
  	font-size: 12px;
  	letter-spacing: 0.84px;
  	line-height: 22px;
  	text-align: center;
    margin-bottom: 45px;
  }

  .client-study_proces-description {
    margin-bottom: 16px;
  }

  .client-study_denegate-description-bold {
  	font-weight: 600;
  }

  .client-study_denegate-description-right {
    margin-bottom: 13px;
  	text-align: right;
  }

  .client-study_denegate-button-container {
    margin-bottom: 40px;
  }

  .denegate-icon-container {
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 27px;

    .img-denegate {
      height: 104px;
    }

    .img-aproved {
      height: 54px;
    }

    .img-process {
      height: 58px;
    }

  }

  .remove-partner {
    margin-top: 25px;
    padding-right: 0;
    .remove-partner-button {
      border-radius: 50%;
      outline: none;
      height: 28px;
      width: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      padding-bottom: 3px;
      border-color: #F60F0F;
      color: #F60F0F;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
    }
  }

  .add-partner {
    margin-top: 25px;
    .add-partner-button {
      border-radius: 50%;
      outline: none;
      height: 28px;
      width: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      padding-bottom: 3px;
      border-color: #115EA0;
      color: #115EA0;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
    }
  }

  .button-file {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .button-transparent {
    background: transparent;
    &:hover {
      color: #343a40;
      opacity: 0.7;
    }
    &:disabled {
      color: #343a40;
      opacity: 1;
    }
  }

  .client-study_copy-info {
  	color: #00B047;
    font-family: "LatoWeb";
  	font-size: 12px;
  	font-style: italic;
  	letter-spacing: 0.63px;
  	line-height: 15px;
    outline: none;
    border: none;
    cursor: pointer;
    padding: 0;
    text-decoration: underline;
    &:hover {
      opacity: 0.7;
    }
  }

  .fau-link-container {
    font-family: "LatoWeb";
    font-size: 12px;
    font-style: italic;
    font-weight: 600;
    letter-spacing: .84px;
    line-height: 19px;
    text-align: center;
    color: #9b9b9b;

    span {
      color: #115ea0;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
        opacity: 0.7;
      }
    }
  }

  .client-study_nit-container {
    div:first-child {
      width: 100%;
    }
  }

  .tooltip-img {
    height: 20px;
    width: 25px;
    margin-left: 5px;
  }

}

.study-modal-succes_title {
  color: #115EA0;
  font-family: "LatoWeb";
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.54px;
  line-height: 19px;
  text-align: center;
  margin-bottom: 20px;
}

.study-modal-succes_description {
  font-family: "LatoWeb";
  color: #8b8b8b;
  font-size: 16px;
  letter-spacing: .39px;
  line-height: 20px;
  text-align: center;
  margin-bottom: 40px;
}

.study-modal-succes_info-key {
  font-family: "LatoWeb";
  color: #6d6d6d;
  font-size: 16px;
  letter-spacing: .39px;
  line-height: 15px;
  text-align: left;
  margin-bottom: 20px;
}

.study-modal-succes_info-value {
  font-family: "LatoWeb";
  color: #6d6d6d;
  font-size: 16px;
  letter-spacing: .39px;
  line-height: 15px;
  text-align: right;
  font-weight: 600;
  margin-bottom: 20px;
}

.study-modal-succes_info-container {
  margin-bottom: 40px;
}

@media only screen and (max-width: 64em) {
  .client-study {
    .sub-container {
      padding: 0;
    }
  }
}

.sub-container {
  padding-left: 40px;
  padding-right: 40px;
}

.modal-end {
  align-items: flex-end;
}
