.list-loader {
  position: relative;
  width: 100%;
  height: 40px;
  margin-top: 10px;

  img {
    height: 100%;
  }
}
