.profile {

  .card-title {
  	color: #4A4A4A;
    font-family: "LatoWeb";
  	font-size: 16px;
  	letter-spacing: 0.84px;
  	line-height: 19px;
    margin-bottom: 30px;
  }

  .profile-margin {
    margin-bottom: 40px;
  }

  .profile_change-photo-container {
    display: flex;
    justify-content: center;
    margin-bottom: 26px;
    flex-wrap: wrap;
  }

  .profile_change-photo {
  	color: #8B8B8B;
    font-family: "LatoWeb";
  	font-size: 12px;
  	font-weight: 300;
  	letter-spacing: 1px;
  	line-height: 14px;
    cursor: pointer;
    text-decoration: underline;
    &:hover {
      opacity: 0.7;
    }
  }

  .profile_user-key {
  	color: #6D6D6D;
    font-family: "LatoWeb";
  	font-size: 14px;
  	letter-spacing: 0.56px;
  	line-height: 16px;
    margin-bottom: 26px;
  }

  .profile_user-value {
  	color: #115EA0;
    font-family: "LatoWeb";
  	font-size: 14px;
  	font-weight: bold;
  	letter-spacing: 0.5px;
  	line-height: 17px;
    margin-bottom: 26px;
  }

  .MuiFormControl-root-1 {
    margin-top: 0px;
  }

  .bith-date {
    margin-bottom: 26px;
  }

  .close-sesion {
    color: #4A4A4A;
    font-family: "LatoWeb";
    font-size: 10px;
    letter-spacing: 0.64px;
    line-height: 12px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 26px;
    padding: 0;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
      text-decoration: underline;
    }
  }

  .goback-icon {
    margin-left: 5px;
  }

  .user-full-logo {
    height: 118px;
    width: 105px;
    margin-bottom: 9px;
    object-fit: cover;
    &:before {
      content: ' ';
      display: block;
      height: 118px;
      width: 105px;
      background-image: url('../../assets/logos/user-logo.svg');
      background-size: 105px 118px;
    }
  }

  .password-container {
    margin-bottom: 10px;
  }

  .change-password_title {
    display: flex;
    justify-content: center;
  	color: #115EA0;
    font-family: "LatoWeb";
  	font-size: 16px;
  	letter-spacing: 0.84px;
  	line-height: 19px;
    margin-bottom: 25px;
  }

  .change-password-button {
    margin-top: 30px;
    margin-bottom: 10px;
  }

  .profile-save-changes {
    margin-bottom: 5px;
  }

}

.confirm-change-title {
	color: #FFFFFF;
  font-family: "LatoWeb";
	font-size: 18px;
	font-weight: 600;
	letter-spacing: 1.71px;
	line-height: 22px;
}

.confirm-change_body-description {
	color: #4A4A4A;
  font-family: "LatoWeb";
	font-size: 16px;
	letter-spacing: 0.84px;
	line-height: 23px;
	text-align: center;
  margin-top: 30px;
  margin-bottom: 40px;
}

.confirm-change-body {
  margin-bottom: 10px;
}
