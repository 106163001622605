.advance-agreements {
  .input-date {
    margin-top: 0px !important;
    margin-bottom: 5px !important;
  }

  .search-date-container {
    height: 100%;
    align-items: flex-end;
  }

  .card-title {
    color: #4a4a4a;
    font-family: "LatoWeb";
    font-size: 16px;
    letter-spacing: 0.84px;
    line-height: 19px;
    margin-bottom: 30px;
  }

  .card-sub-title {
    color: #4a4a4a;
    font-family: "LatoWeb";
    font-size: 14px;
    letter-spacing: 0.84px;
    line-height: 19px;
    margin-top: 10px;
  }

  .advance-agreements-margin {
    margin-bottom: 23px;
  }

  .input-error {
    color: red;
    width: 100px;
  }

  .advance-agreements-table-container {
    margin-bottom: 10px;
    margin-top: 20px;
    display: block;
    width: 100%;
    overflow: auto;
    height: 250px;

    th {
      color: #115ea0;
      font-family: "LatoWeb";
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0.73px;
      line-height: 13px;
      padding-top: 13px;
      padding-bottom: 13px;
      padding-left: 20px;
      vertical-align: middle;
    }

    .notice-th {
      color: #00b047;
      font-family: "LatoWeb";
      font-size: 14px;
      font-style: italic;
      font-weight: bold;
      letter-spacing: 0.73px;
      line-height: 13px;
      text-align: center;
    }

    td {
      vertical-align: middle;
      padding-left: 20px;
      color: #6d6d6d;
      font-family: "LatoWeb";
      font-size: 14px;
      letter-spacing: 0.73px;
      line-height: 13px;
      white-space: nowrap;
    }

    .notice-td-container {
      display: flex;
      text-decoration: underline;
    }

    .notice-td {
      color: #9b9b9b;
      font-family: "LatoWeb";
      font-size: 14px;
      font-style: italic;
      letter-spacing: 0.73px;
      line-height: 13px;
    }

    .input-agreement {
      width: 100px;
    }

    .enabled-link {
      cursor: pointer;
      color: #00b047;
      &:hover {
        opacity: 0.7;
      }
    }

    .document {
      color: #00b047;
      text-decoration: underline;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
    }

    .center {
      text-align: center;
    }

    .subtitle {
      color: #4a4a4a;
      font-family: "LatoWeb";
      font-size: 12px;
      font-style: italic;
      letter-spacing: 0.63px;
      line-height: 15px;
      text-align: center;
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }

  .subheader_name {
    color: #4a4a4a;
    font-family: "LatoWeb";
    font-size: 14px;
    letter-spacing: 0.73px;
    line-height: 17px;
    margin-bottom: 9px;
  }

  .subheader_value {
    color: #115ea0;
    font-family: "LatoWeb";
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.84px;
    line-height: 19px;
    margin-bottom: 9px;
  }

  .subheader_value-border {
    border-right: solid 1px #d8d8d8;
  }

  .subheader_container {
    margin-bottom: 15px;
  }
}

.exoneraton-search_title {
  color: #115ea0;
  font-family: "LatoWeb";
  font-size: 14px;
  font-style: italic;
  letter-spacing: 0.73px;
  line-height: 17px;
}

.exoneraton-col-padding {
  padding-top: 10px;
  .MuiFormControl-root-1 {
    margin-bottom: 5px;
    margin-top: 0px;
  }

  .MuiInputBase-root-36 {
    margin-top: 10px;
  }
}

.exoneraton-date-boder {
  border-right: solid 1px #d8d8d8;
}

.refund-search_container-col {
  min-height: 60px;
}

.refund-search_container {
  width: 100%;
  position: absolute;
  bottom: 0;
}

@media only screen and (max-width: 64em) {
  .exoneraton-date-boder {
    border-right: none;
  }
}

.confirm-advance-agreements-modal-title {
  color: #FFFFFF;
  font-family: "LatoWeb";
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1.71px;
  line-height: 22px;
}

.confirm-advance-agreements_info-key {
	color: #4A4A4A;
  font-family: "LatoWeb";
	font-size: 14px;
	letter-spacing: 0.73px;
	line-height: 19px;
}

.confirm-advance-agreements_info-bottom {
	color: #4A4A4A;
  font-family: "LatoWeb";
	font-size: 12px;
	letter-spacing: 0.73px;
	line-height: 19px;
}

.confirm-advance-agreements_info-value {
	color: #4A4A4A;
  font-family: "LatoWeb";
	font-size: 14px;
	font-weight: bold;
	letter-spacing: 0.73px;
	line-height: 19px;
	text-align: right;
  text-transform: capitalize;
}

.confirm-advance-agreements_info-container {
  margin-bottom: 14px;
}

.confirm-advance-agreements_info-separator {
  width: 100%;
}

.confirm-advance-agreements_info-button-container {
  margin-bottom: 26px;
  margin-top: 36px;
}

.confirm-advance-agreements_info-end-button-container {
  margin-top: 36px;
}

.confirm-advance-agreements_info-center {
  display: flex;
  align-items: center;
}
