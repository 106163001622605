.login {
  background: #115EA0;
  display: flex;
  flex-wrap: wrap;
  .login_title {
    font-family: "LatoWebBold";
    font-style: italic;
    font-size: 24px;
    color: #FFFFFF;
    letter-spacing: 1.74px;
    line-height: 29px;
    margin: 22px 0;
  }

  .login_title_line-container {
    display: flex;
    align-items: center;
    margin-left: -15px;
  }

  .login_title_line {
    border-bottom: 1px solid #FFFFFF;
    width: 100%;
  }

  .login_title_line-point {
    width: 5px;
    height: 5px;
    background: #FFFFFF;
    border-radius: 50%;
  }

  .login_sub-title {
    font-family: "LatoWeb";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #FFFFFF;
    letter-spacing: 0.87px;
    line-height: 18px;
    text-align: center;
  }

  .login_error-login {
  	color: #FFFFFF;
    font-family: "LatoWeb";
  	font-size: 14px;
  	font-weight: 500;
  	letter-spacing: 0.81px;
  	line-height: 17px;
  	text-align: center;
  }

  .login_error-logo-container {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 11px;
  }

  .login_error-logo {
    height: 35px;
  }

  .image-cover {
    background-image: url('../../assets/images/Fondo-login.png');
    background-repeat: no-repeat;
    background-size: cover;
  }

  .login-logo {
    height: 28px;
  }

  .login-logo-container {
    margin-top: 88px;
  }

  .login_sub-title-container {
    margin-top: 88px;
  }

  .button-send-container {
    margin-top: 54px;
  }

  .button-link-container {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .remember-margin {
    margin-top: 40px;
    margin-bottom: 30px;
  }

  .remember_send-email {
    margin-top: 70px;
    margin-bottom: 70px;
  }

}
